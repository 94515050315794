import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import _ from 'lodash';

import { NotificationType } from '@wix/platform-editor-sdk/lib';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  const t = flowAPI.translations.t;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const giftCardPage = _.find(allSitePages, { tpaPageId: 'Gift Card' });

  console.log('Gift Card - EditorReady', {
    firstInstall: platformOptions.firstInstall,
    giftCardPageID: giftCardPage?.id,
  });

  if (platformOptions.firstInstall) {
    const type = 'success' as NotificationType;
    editorSDK.editor
      .showUserActionNotification('', {
        message: t(
          'settings.giftCardPage.floatingNotifcation.giftCardAdded.text',
        ),
        type,
        link: {
          caption: t(
            'settings.giftCardPage.floatingNotifcation.customizePage.link',
          ),
        },
      })
      .then((linkClicked) => {
        linkClicked &&
          editorSDK.document.pages.navigateTo('', {
            pageRef: {
              id: giftCardPage?.id as string,
            },
          });
      });
  }
};
